/// <reference types="./computed.d.mts" />
import * as $project from "../../../common/data/project.mjs";
import * as $proposal from "../../../common/data/proposal.mjs";
import * as $question from "../../../common/data/proposal_element/question.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $data from "../../data/model/data.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

export class Computed extends $CustomType {
  constructor(indexed_pii_vocabulary) {
    super();
    this.indexed_pii_vocabulary = indexed_pii_vocabulary;
  }
}

export function new$() {
  return new Computed(toList([]));
}

export function indexed_pii_vocabulary(data, computed) {
  let _pipe = $option.map(
    data.ai_config,
    (ai_config) => {
      let indexed_pii_vocabulary$1 = (() => {
        let _pipe = ai_config.config.pii_vocabulary;
        return $list.index_map(_pipe, (x, i) => { return [i, x]; });
      })();
      return new Computed(indexed_pii_vocabulary$1);
    },
  );
  return $option.unwrap(_pipe, computed);
}
