/// <reference types="./config.d.mts" />
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $s from "../../../sketch/sketch.mjs";
import * as $size from "../../../sketch/sketch/size.mjs";
import { px } from "../../../sketch/sketch/size.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function section_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function subsection_title(attrs, children) {
  let _pipe = $s.class$(toList([$s.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function pii_tag_icon_delete(attrs, child) {
  let _pipe = $s.class$(
    toList([$s.width(px(16)), $s.height(px(16)), $s.cursor("pointer")]),
  );
  return $h.button(_pipe, attrs, toList([child]));
}
