/// <reference types="./route.d.mts" />
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None } from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $modem from "../../modem/modem.mjs";
import { updateTitle as do_update_title } from "../admin.ffi.mjs";
import { Ok, CustomType as $CustomType } from "../gleam.mjs";

export class DataSources extends $CustomType {}

export class IngestionControl extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class StyleGuidelines extends $CustomType {}

export class Config extends $CustomType {}

export class Overview extends $CustomType {}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AiConfig extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AiSearch extends $CustomType {}

export class NotFound extends $CustomType {}

export function from_uri(uri) {
  let $ = $uri.path_segments(uri.path);
  if ($.hasLength(0)) {
    return new Overview();
  } else if ($.hasLength(1) && $.head === "content-library") {
    return new ContentLibrary(new DataSources());
  } else if ($.hasLength(2) && $.head === "ingestion-control") {
    let source_id = $.tail.head;
    return new ContentLibrary(new IngestionControl(source_id));
  } else if ($.hasLength(1) && $.head === "style-guidelines") {
    return new AiConfig(new StyleGuidelines());
  } else if ($.hasLength(1) && $.head === "config") {
    return new AiConfig(new Config());
  } else if ($.hasLength(1) && $.head === "ai-search") {
    return new AiSearch();
  } else {
    return new NotFound();
  }
}

function not_found() {
  return new $uri.Uri(
    new None(),
    new None(),
    new None(),
    new None(),
    "#",
    new None(),
    new None(),
  );
}

export function to_uri(route) {
  let _pipe = (() => {
    if (route instanceof Overview) {
      return $uri.parse("/");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof DataSources) {
      return $uri.parse("/content-library");
    } else if (route instanceof ContentLibrary &&
    route[0] instanceof IngestionControl) {
      let source_id = route[0].source_id;
      return $uri.parse("/ingestion-control/" + source_id);
    } else if (route instanceof AiConfig && route[0] instanceof StyleGuidelines) {
      return $uri.parse("/style-guidelines");
    } else if (route instanceof AiConfig && route[0] instanceof Config) {
      return $uri.parse("/config");
    } else if (route instanceof AiSearch) {
      return $uri.parse("/ai-search");
    } else {
      return new Ok(not_found());
    }
  })();
  return $result.lazy_unwrap(_pipe, not_found);
}

export function update_title(route) {
  return $effect.from(
    (_) => {
      let title = (() => {
        if (route instanceof Overview) {
          return "Overview";
        } else if (route instanceof ContentLibrary) {
          return "Content Library";
        } else if (route instanceof AiConfig) {
          return "AI Config";
        } else if (route instanceof AiSearch) {
          return "AI Search";
        } else {
          return "Not Found";
        }
      })();
      do_update_title("Admin — " + title);
      return undefined;
    },
  );
}

export function push(route) {
  let uri = to_uri(route);
  return $modem.push(uri.path, uri.query, uri.fragment);
}

export function replace(route) {
  let uri = to_uri(route);
  return $modem.replace(uri.path, uri.query, uri.fragment);
}
