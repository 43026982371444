/// <reference types="./app_config.d.mts" />
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $vitools from "../vitools/vitools.mjs";

export function wss_endpoint() {
  return $bool.guard(
    $vitools.is_dev(),
    "ws://localhost:3000/wss",
    () => {
      let _pipe = $vitools.get_env("API_ENDPOINT");
      let _pipe$1 = $result.unwrap(_pipe, "");
      let _pipe$2 = $string.replace(_pipe$1, "http", "ws");
      let _pipe$3 = $string.replace(_pipe$2, "https", "wss");
      return $string.append(_pipe$3, "/wss");
    },
  );
}

export const localhost = "http://localhost:3000";

export function heimdall_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/heimdall";
  } else {
    return $result.unwrap($vitools.get_env("API_ENDPOINT"), "") + "/heimdall";
  }
}

export function loki_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/loki";
  } else {
    return $result.unwrap($vitools.get_env("API_ENDPOINT"), "") + "/loki";
  }
}

export function nabu_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/nabu";
  } else {
    return $result.unwrap($vitools.get_env("API_ENDPOINT"), "") + "/nabu";
  }
}

export function hermes_endpoint() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/hermes/slack/redirect";
  } else {
    return $result.unwrap($vitools.get_env("API_ENDPOINT"), "") + "/hermes";
  }
}
