/// <reference types="./ai_config.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $tab_bar from "../../ds/ds/components/tab_bar.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError, isEqual } from "../gleam.mjs";
import * as $config from "../view/config.mjs";
import * as $style_guidelines from "../view/style_guidelines.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.AiConfig)) {
    throw makeError(
      "let_assert",
      "view/ai_config",
      14,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  let _pipe = $el.fragment(
    toList([
      $tab_bar.tab_bar(
        toList([]),
        toList([
          $tab_bar.tab(
            isEqual(route, new $route.StyleGuidelines()),
            toList([
              (() => {
                let _pipe = $route.to_uri(
                  new $route.AiConfig(new $route.StyleGuidelines()),
                );
                let _pipe$1 = $uri.to_string(_pipe);
                return $a.href(_pipe$1);
              })(),
            ]),
            toList([$el.text("Style Guidelines")]),
          ),
          $tab_bar.tab(
            isEqual(route, new $route.Config()),
            toList([
              (() => {
                let _pipe = $route.to_uri(
                  new $route.AiConfig(new $route.Config()),
                );
                let _pipe$1 = $uri.to_string(_pipe);
                return $a.href(_pipe$1);
              })(),
            ]),
            toList([$el.text("Config")]),
          ),
        ]),
      ),
      (() => {
        if (route instanceof $route.StyleGuidelines) {
          return $style_guidelines.view(model);
        } else {
          return $config.view(model);
        }
      })(),
    ]),
  );
  return $el.map(_pipe, (var0) => { return new $msg.AiConfig(var0); });
}
