/// <reference types="./ai_search.d.mts" />
import * as $ai_config from "../../../common/data/ai_config.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_ai_search_results(data) {
  return $middleware.require_access_token(
    data,
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $middleware.require_user_id(
            data,
            (user_id) => {
              return $middleware.require_org_id(
                data,
                (org_id) => {
                  return $effect.from(
                    (dispatch) => {
                      let config = (() => {
                        let _pipe = data.ai_config;
                        let _pipe$1 = $option.map(
                          _pipe,
                          (x) => { return x.config; },
                        );
                        return $option.unwrap(
                          _pipe$1,
                          $ai_config.default_config(),
                        );
                      })();
                      let _pipe = $ask.to(new $ask.Nabu(), toList(["search"]));
                      let _pipe$1 = $ask.bearing(_pipe, access_token);
                      let _pipe$2 = $ask.via(_pipe$1, new $http.Post());
                      let _pipe$3 = $ask.with$(
                        _pipe$2,
                        $json.object(
                          toList([
                            [
                              "ai_search_input",
                              $json.string(data.ai_search_input),
                            ],
                            ["org_id", $json.string(org_id)],
                            ["session_id", $json.string(user_id)],
                            ["config", $ai_config.encode_config(config)],
                          ]),
                        ),
                      );
                      let _pipe$4 = $ask.expect(
                        _pipe$3,
                        $dynamic.list(
                          $dynamic.decode2(
                            (a, b) => { return [a, b]; },
                            $dynamic.field("data_point", $data_point.decode),
                            $dynamic.field("score", $dynamic.float),
                          ),
                        ),
                      );
                      let _pipe$5 = $ask.notify(
                        _pipe$4,
                        (d) => {
                          return dispatch(
                            new $msg.ApiReturnedAiSearchResults(d),
                          );
                        },
                      );
                      $ask.run(_pipe$5)
                      return undefined;
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
