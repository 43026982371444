/// <reference types="./table.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $s from "../../../../sketch/sketch.mjs";
import * as $size from "../../../../sketch/sketch/size.mjs";
import { percent, px } from "../../../../sketch/sketch/size.mjs";
import * as $h from "../../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function table(columns, attrs, children) {
  let template = columns[1];
  let _pipe = $s.class$(
    toList([
      $s.border_radius(px(8)),
      $s.border("1px solid " + $colors.light.grey_400),
      $s.display("grid"),
      $s.grid_template_columns(template),
      $s.overflow("auto"),
    ]),
  );
  return $h.table(_pipe, attrs, children);
}

export function theader(attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.compose($text.sm($text.medium)),
      $s.padding_("10px 20px"),
      $s.background($colors.light.grey_100),
      $s.color($colors.light.onyx),
      $s.white_space("nowrap"),
    ]),
  );
  return $h.th(_pipe, attrs, children);
}

export function tfoot(columns, attrs, children) {
  let columns$1 = $int.to_string(columns);
  let _pipe = $s.class$(
    toList([
      $s.display("flex"),
      $s.grid_column("span " + columns$1),
      $s.border_top("1px solid " + $colors.light.grey_200),
      $s.justify_content("space-between"),
      $s.pseudo_selector(
        "[disabled='True']",
        toList([$s.pointer_events("none"), $s.opacity(0.3)]),
      ),
    ]),
  );
  return $h.tfoot(_pipe, attrs, children);
}

export function tcell(custom_style, attrs, children) {
  let _pipe = $s.class$(
    toList([
      $s.padding(px(20)),
      $s.compose($s.class$(custom_style)),
      $s.padding_right(px(20)),
      $s.padding_left(px(20)),
      $s.overflow("visible"),
      $s.text_overflow("ellipsis"),
      $s.max_width(percent(100)),
      $s.white_space("wrap"),
      $s.hover(toList([$s.background("inherit")])),
    ]),
  );
  return $h.td(_pipe, attrs, children);
}

export function footer_dropdown(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(24))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function footer_actions(attrs, children) {
  let _pipe = $s.class$(
    toList([$s.display("flex"), $s.align_items("center"), $s.gap(px(16))]),
  );
  return $h.div(_pipe, attrs, children);
}

function subgrid(count) {
  let column_end = $int.to_string(count + 1);
  return $s.class$(
    toList([
      $s.display("grid"),
      $s.grid_template_columns("subgrid"),
      $s.grid_column("1 / " + column_end),
    ]),
  );
}

export function thead(columns, attrs, children) {
  let count = columns[0];
  let _pipe = $s.class$(
    toList([
      $s.compose(subgrid(count)),
      $s.border_bottom("1px solid " + $colors.light.grey_400),
      $s.text_align("left"),
      $s.last_child(toList([$s.border_bottom("none")])),
      $s.border_top_right_radius(px(7)),
      $s.border_top_left_radius(px(7)),
      $s.overflow("hidden"),
    ]),
  );
  return $h.thead(_pipe, attrs, children);
}

export function thr(column, attrs, children) {
  let count = column[0];
  return $h.tr($s.class$(toList([$s.compose(subgrid(count))])), attrs, children);
}

export function tbody(columns, attrs, children) {
  let count = columns[0];
  let _pipe = $s.class$(
    toList([
      $s.compose(subgrid(count)),
      $s.compose($text.sm($text.regular)),
      $s.color($colors.light.paragraph),
      $s.white_space("nowrap"),
      $s.overflow("auto"),
    ]),
  );
  return $h.tbody(_pipe, attrs, children);
}

export function tr(columns, hover, attrs, children) {
  let count = columns[0];
  let _pipe = $s.class$(
    toList([
      $s.compose(subgrid(count)),
      $s.align_items("center"),
      $s.hover(
        (() => {
          if (hover) {
            return toList([
              $s.background($colors.light.grey_100),
              $s.cursor("pointer"),
            ]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
  return $h.tr(_pipe, attrs, children);
}

export function separator(columns) {
  let count = columns[0];
  let _pipe = $s.class$(
    toList([
      $s.compose(subgrid(count)),
      $s.height(px(1)),
      $s.background($colors.light.grey_200),
    ]),
  );
  return $h.tr(_pipe, toList([]), toList([]));
}

export function icon_wrapper(attrs, children) {
  return $h.div(
    $s.class$(toList([$s.width(px(20)), $s.height(px(20))])),
    attrs,
    children,
  );
}
