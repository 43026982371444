import admin/auth
import admin/effects
import admin/effects/ai_search
import admin/ffi
import auth0/client as auth0
import bright
import data/model.{type Model}
import data/model/computed
import data/model/data.{type Data, Data}
import data/msg.{type Msg}
import data/route
import ds
import gleam/float
import gleam/io
import gleam/list
import gleam/option
import gleam/pair
import gleam/result
import grille_pain
import lustre
import lustre/effect
import modem
import sentry
import sketch
import sketch/magic
import update/ai_config.{update as update_ai_config} as _
import update/content_library.{update as update_content_library} as _
import view/layout
import view/login
import vitools

fn sentry_init() {
  vitools.get_env("SENTRY_DSN")
  |> result.map_error(fn(_) { io.print_error("No VITE_SENTRY_DSN found") })
  |> result.map(sentry.init)
}

fn sketch_init() {
  use cache <- result.try(sketch.cache(strategy: sketch.Ephemeral))
  use _ <- result.try(magic.setup(cache))
  Ok(magic.document())
}

pub fn main() {
  let assert Ok(_) = ds.setup()
  let assert Ok(_) = grille_pain.simple()
  let assert Ok(_) = sentry_init()
  let assert Ok(flags) = initial_flags()
  let assert Ok(stylesheet) = sketch_init()
  let assert Ok(_dispatch) =
    view(_, stylesheet)
    |> lustre.application(init, update, _)
    |> lustre.start("#app", flags)
}

fn initial_flags() {
  use initial_route <- result.map(initial_route())
  let auth0_client = auth.client(mode: auth.Authentication)
  let collapsed_navbar = initial_navbar_state()
  #(initial_route, auth0_client, collapsed_navbar)
}

fn initial_route() {
  use uri <- result.map(modem.initial_uri())
  route.from_uri(uri)
}

fn initial_navbar_state() {
  False
}

fn init(flags: #(route.Route, auth0.Client, Bool)) {
  let #(route, client, _collapsed_navbar) = flags
  let data = data.new(client, route)
  use model <- bright.start(bright.init(data, computed.new()))
  model
  |> bright.schedule(fn(_, _) { auth0.init(client, msg.AuthStateChanged) })
  |> bright.schedule(fn(_, _) { modem.init(msg.ApplicationChangedRoute) })
  |> bright.schedule(fn(_, _) { route.update_title(route) })
  |> bright.schedule(fn(_, _) {
    effect.from(fn(_) { ffi.subscribe_feed_scroll() })
  })
}

fn update(model: Model, msg: Msg) -> #(Model, effect.Effect(Msg)) {
  use model <- bright.start(model)
  model
  |> bright.update(update_data(_, msg))
  |> bright.compute(computed.indexed_pii_vocabulary)
}

fn update_data(data: Data, msg: Msg) -> #(Data, effect.Effect(Msg)) {
  case msg {
    msg.ContentLibrary(msg) -> update_content_library(data, msg)

    msg.AiConfig(msg) -> update_ai_config(data, msg)

    msg.ApplicationChangedRoute(route) -> {
      let route = route.from_uri(route)
      let model = data.update_route(data, route)
      model
      |> pair.new(effect.batch([route.update_title(route), effect.none()]))
    }

    msg.AuthStateChanged(user) -> {
      case user {
        option.None ->
          data.new(data.client, data.route)
          |> pair.new(effect.none())
        option.Some(#(access_token, user)) -> {
          data
          |> data.update_access_token(access_token)
          |> data.update_connected_user(user)
          |> fn(data) { #(data, effects.on_connected_user(data)) }
        }
      }
    }

    msg.Auth0(auth0) ->
      case auth0 {
        auth0.Authorize(_) -> #(data, effect.none())
        auth0.Authorized(_) -> #(data, effect.none())
        auth0.Login -> {
          let handler = auth0.login_with_redirect
          data.client
          |> handler(msg.AuthStateChanged)
          |> pair.new(data, _)
        }
        auth0.Logout ->
          data.client
          |> auth0.logout(msg.AuthStateChanged)
          |> pair.new(data, _)
      }

    msg.UserUpdatedSearchInput(content) -> {
      data.set_ai_search_input(data, content)
      |> pair.new(effect.none())
    }

    msg.UserSubmittedAiSearch -> {
      ai_search.fetch_ai_search_results(data)
      |> pair.new(data, _)
    }

    msg.ApiReturnedAiSearchResults(ai_search_results:) -> {
      ai_search_results
      |> list.sort(fn(a, b) { float.compare(b.1, a.1) })
      |> fn(ai_search_results) { data.Data(..data, ai_search_results:) }
      |> pair.new(effect.none())
    }
    // msg.ContentLibrary(msg) -> update_content_library(data, msg)
  }
}

fn view(model: Model, stylesheet: magic.StyleSheet) {
  use <- magic.render([stylesheet])
  let #(data, _) = bright.unwrap(model)
  case data.route, data.is_connected(data) {
    _, False -> login.view()
    _, True -> layout.view(model)
  }
}
