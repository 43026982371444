/// <reference types="./effects.d.mts" />
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $ai_config from "../admin/effects/ai_config.mjs";
import * as $content_library_effects from "../admin/effects/content_library.mjs";
import * as $ffi from "../admin/ffi.mjs";
import * as $data from "../data/model/data.mjs";
import { Data } from "../data/model/data.mjs";
import { toList } from "../gleam.mjs";

export function subscribe_dom_click(on_unsubscriber, on_click) {
  return $effect.from(
    (dispatch) => {
      let unsubscriber = $ffi.subscribe_dom_click(
        () => { return dispatch(on_click); },
      );
      return dispatch(on_unsubscriber(unsubscriber));
    },
  );
}

export function on_connected_user(data) {
  return $effect.batch(
    toList([
      $content_library_effects.fetch_content_library(data),
      $ai_config.fetch_ai_config(data),
    ]),
  );
}
