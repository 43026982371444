/// <reference types="./sentry.d.mts" />
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import { Error } from "./gleam.mjs";
import {
  init,
  captureMessage as capture_message,
  captureError as do_capture_error,
} from "./sentry.ffi.mjs";

export { capture_message, init };

export function capture_error(error) {
  return $result.try_recover(
    error,
    (content) => {
      let content_ = $string.inspect(content);
      let $ = do_capture_error(content_);
      
      return new Error(content);
    },
  );
}

export function capture(error) {
  let error$1 = $string.inspect(error);
  return do_capture_error(error$1);
}
