/// <reference types="./translate.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType, isEqual } from "../gleam.mjs";

export class Translate extends $CustomType {
  constructor(content, id, kind, org_id, target_language) {
    super();
    this.content = content;
    this.id = id;
    this.kind = kind;
    this.org_id = org_id;
    this.target_language = target_language;
  }
}

export class DataPoint extends $CustomType {}

export class Question extends $CustomType {}

export class Answer extends $CustomType {}

export class None extends $CustomType {}

export class ENGLISH extends $CustomType {}

export class FRENCH extends $CustomType {}

export class GERMAN extends $CustomType {}

export class SPANISH extends $CustomType {}

export class DUTCH extends $CustomType {}

export class PORTUGUESE extends $CustomType {}

export class ITALIAN extends $CustomType {}

export class FINNISH extends $CustomType {}

export class SWEDISH extends $CustomType {}

export class NYNORSK extends $CustomType {}

export class BOKMAL extends $CustomType {}

export class DANISH extends $CustomType {}

export class ESTONIAN extends $CustomType {}

export class LATVIAN extends $CustomType {}

export class LITHUANIAN extends $CustomType {}

export class ICELANDIC extends $CustomType {}

export class TranslationMetadata extends $CustomType {
  constructor(target_type) {
    super();
    this.target_type = target_type;
  }
}

export class Translation extends $CustomType {
  constructor(id, target_id, content, language, metadata) {
    super();
    this.id = id;
    this.target_id = target_id;
    this.content = content;
    this.language = language;
    this.metadata = metadata;
  }
}

export class Translated extends $CustomType {
  constructor(org_id, id, content, target_language, kind) {
    super();
    this.org_id = org_id;
    this.id = id;
    this.content = content;
    this.target_language = target_language;
    this.kind = kind;
  }
}

export function decode_language(l) {
  if (l instanceof ENGLISH) {
    return "english";
  } else if (l instanceof FRENCH) {
    return "french";
  } else if (l instanceof GERMAN) {
    return "german";
  } else if (l instanceof SPANISH) {
    return "spanish";
  } else if (l instanceof DUTCH) {
    return "dutch";
  } else if (l instanceof PORTUGUESE) {
    return "portuguese";
  } else if (l instanceof ITALIAN) {
    return "italian";
  } else if (l instanceof FINNISH) {
    return "finnish";
  } else if (l instanceof SWEDISH) {
    return "swedish";
  } else if (l instanceof NYNORSK) {
    return "nynorsk";
  } else if (l instanceof BOKMAL) {
    return "bokmal";
  } else if (l instanceof DANISH) {
    return "danish";
  } else if (l instanceof ESTONIAN) {
    return "estonian";
  } else if (l instanceof LATVIAN) {
    return "latvian";
  } else if (l instanceof LITHUANIAN) {
    return "lithuanian";
  } else {
    return "icelandic";
  }
}

export function language_to_emoji(l) {
  if (l instanceof ENGLISH) {
    return "🇬🇧";
  } else if (l instanceof FRENCH) {
    return "🇫🇷";
  } else if (l instanceof SPANISH) {
    return "🇪🇸";
  } else if (l instanceof GERMAN) {
    return "🇩🇪";
  } else if (l instanceof DUTCH) {
    return "🇳🇱";
  } else if (l instanceof PORTUGUESE) {
    return "🇵🇹";
  } else if (l instanceof ITALIAN) {
    return "🇮🇹";
  } else if (l instanceof FINNISH) {
    return "🇫🇮";
  } else if (l instanceof SWEDISH) {
    return "🇸🇪";
  } else if (l instanceof NYNORSK) {
    return "🇳🇴";
  } else if (l instanceof BOKMAL) {
    return "🇳🇴";
  } else if (l instanceof DANISH) {
    return "🇩🇰";
  } else if (l instanceof ESTONIAN) {
    return "🇪🇪";
  } else if (l instanceof LATVIAN) {
    return "🇱🇻";
  } else if (l instanceof LITHUANIAN) {
    return "🇱🇹";
  } else {
    return "🇮🇸";
  }
}

export function language_to_yes(l) {
  if (l instanceof ENGLISH) {
    return "Yes";
  } else if (l instanceof FRENCH) {
    return "Oui";
  } else if (l instanceof SPANISH) {
    return "Sí";
  } else if (l instanceof GERMAN) {
    return "Ja";
  } else if (l instanceof DUTCH) {
    return "Ja";
  } else if (l instanceof PORTUGUESE) {
    return "Sim";
  } else if (l instanceof ITALIAN) {
    return "Sì";
  } else if (l instanceof FINNISH) {
    return "Kyllä";
  } else if (l instanceof SWEDISH) {
    return "Ja";
  } else if (l instanceof NYNORSK) {
    return "Ja";
  } else if (l instanceof BOKMAL) {
    return "Ja";
  } else if (l instanceof DANISH) {
    return "Ja";
  } else if (l instanceof ESTONIAN) {
    return "Jah";
  } else if (l instanceof LATVIAN) {
    return "Jā";
  } else if (l instanceof LITHUANIAN) {
    return "Taip";
  } else {
    return "Já";
  }
}

export function language_to_no(l) {
  if (l instanceof ENGLISH) {
    return "No";
  } else if (l instanceof FRENCH) {
    return "Non";
  } else if (l instanceof SPANISH) {
    return "No";
  } else if (l instanceof GERMAN) {
    return "Nein";
  } else if (l instanceof DUTCH) {
    return "Nee";
  } else if (l instanceof PORTUGUESE) {
    return "Não";
  } else if (l instanceof ITALIAN) {
    return "No";
  } else if (l instanceof FINNISH) {
    return "Ei";
  } else if (l instanceof SWEDISH) {
    return "Nej";
  } else if (l instanceof NYNORSK) {
    return "Nei";
  } else if (l instanceof BOKMAL) {
    return "Nei";
  } else if (l instanceof DANISH) {
    return "Nej";
  } else if (l instanceof ESTONIAN) {
    return "Ei";
  } else if (l instanceof LATVIAN) {
    return "Nē";
  } else if (l instanceof LITHUANIAN) {
    return "Ne";
  } else {
    return "Nei";
  }
}

export function language_to_na(l) {
  if (l instanceof ENGLISH) {
    return "N/A";
  } else if (l instanceof FRENCH) {
    return "N/A";
  } else if (l instanceof SPANISH) {
    return "N/A";
  } else if (l instanceof GERMAN) {
    return "N/V";
  } else if (l instanceof DUTCH) {
    return "N/B";
  } else if (l instanceof PORTUGUESE) {
    return "N/D";
  } else if (l instanceof ITALIAN) {
    return "N/D";
  } else if (l instanceof FINNISH) {
    return "E/T";
  } else if (l instanceof SWEDISH) {
    return "E/T";
  } else if (l instanceof NYNORSK) {
    return "I/T";
  } else if (l instanceof BOKMAL) {
    return "I/T";
  } else if (l instanceof DANISH) {
    return "I/T";
  } else if (l instanceof ESTONIAN) {
    return "P/V";
  } else if (l instanceof LATVIAN) {
    return "N/P";
  } else if (l instanceof LITHUANIAN) {
    return "N/A";
  } else {
    return "N/A";
  }
}

export function translate_yes_no_answer(answer, l) {
  if (answer === "YES") {
    return language_to_yes(l);
  } else if (answer === "NO") {
    return language_to_no(l);
  } else if (answer === "NA") {
    return language_to_na(l);
  } else {
    return answer;
  }
}

function display_non_default_language(l) {
  return (language_to_emoji(l) + " ") + (() => {
    let _pipe = decode_language(l);
    return $string.capitalise(_pipe);
  })();
}

function display_default_language(l) {
  return ((language_to_emoji(l) + " ") + $string.capitalise(decode_language(l))) + " (default)";
}

export function display_language(language, default_language) {
  let is_default_language = (() => {
    if (default_language instanceof $option.Some) {
      let default_language$1 = default_language[0];
      return isEqual(default_language$1, language);
    } else {
      return false;
    }
  })();
  if (is_default_language) {
    return display_default_language(language);
  } else {
    return display_non_default_language(language);
  }
}

export function decode_language_iso639_1(l) {
  if (l instanceof ENGLISH) {
    return "en";
  } else if (l instanceof FRENCH) {
    return "fr";
  } else if (l instanceof GERMAN) {
    return "de";
  } else if (l instanceof SPANISH) {
    return "es";
  } else if (l instanceof DUTCH) {
    return "nl";
  } else if (l instanceof PORTUGUESE) {
    return "pt";
  } else if (l instanceof ITALIAN) {
    return "it";
  } else if (l instanceof FINNISH) {
    return "fi";
  } else if (l instanceof SWEDISH) {
    return "sv";
  } else if (l instanceof NYNORSK) {
    return "nn";
  } else if (l instanceof BOKMAL) {
    return "nb";
  } else if (l instanceof DANISH) {
    return "da";
  } else if (l instanceof ESTONIAN) {
    return "et";
  } else if (l instanceof LATVIAN) {
    return "lv";
  } else if (l instanceof LITHUANIAN) {
    return "lt";
  } else {
    return "is";
  }
}

export function decode_language_iso639_1_dyn(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk()) {
    let l = $[0];
    if (l === "en") {
      return new Ok(new ENGLISH());
    } else if (l === "fr") {
      return new Ok(new FRENCH());
    } else if (l === "es") {
      return new Ok(new SPANISH());
    } else if (l === "de") {
      return new Ok(new GERMAN());
    } else if (l === "nl") {
      return new Ok(new DUTCH());
    } else if (l === "pt") {
      return new Ok(new PORTUGUESE());
    } else if (l === "it") {
      return new Ok(new ITALIAN());
    } else if (l === "fi") {
      return new Ok(new FINNISH());
    } else if (l === "sv") {
      return new Ok(new SWEDISH());
    } else if (l === "nn") {
      return new Ok(new NYNORSK());
    } else if (l === "nb") {
      return new Ok(new BOKMAL());
    } else if (l === "da") {
      return new Ok(new DANISH());
    } else if (l === "et") {
      return new Ok(new ESTONIAN());
    } else if (l === "lv") {
      return new Ok(new LATVIAN());
    } else if (l === "lt") {
      return new Ok(new LITHUANIAN());
    } else if (l === "is") {
      return new Ok(new ICELANDIC());
    } else {
      return new Error(toList([]));
    }
  } else {
    let e = $[0];
    return new Error(e);
  }
}

export function from_iso_string_to_display_string(iso_string) {
  if (iso_string === "fr") {
    return "French";
  } else if (iso_string === "en") {
    return "English";
  } else if (iso_string === "es") {
    return "Spanish";
  } else if (iso_string === "de") {
    return "German";
  } else if (iso_string === "nl") {
    return "Dutch";
  } else if (iso_string === "pt") {
    return "Portuguese";
  } else if (iso_string === "it") {
    return "Italian";
  } else if (iso_string === "fi") {
    return "Finnish";
  } else if (iso_string === "sv") {
    return "Swedish";
  } else if (iso_string === "nn") {
    return "Nynorsk";
  } else if (iso_string === "nb") {
    return "Bokmal";
  } else if (iso_string === "da") {
    return "Danish";
  } else if (iso_string === "et") {
    return "Estonian";
  } else if (iso_string === "lv") {
    return "Latvian";
  } else if (iso_string === "lt") {
    return "Lithuanian";
  } else if (iso_string === "is") {
    return "Icelandic";
  } else {
    return iso_string;
  }
}

export function decode_iso639_1(l) {
  if (l === "en") {
    return $decode.into(new ENGLISH());
  } else if (l === "fr") {
    return $decode.into(new FRENCH());
  } else if (l === "es") {
    return $decode.into(new SPANISH());
  } else if (l === "de") {
    return $decode.into(new GERMAN());
  } else if (l === "nl") {
    return $decode.into(new DUTCH());
  } else if (l === "pt") {
    return $decode.into(new PORTUGUESE());
  } else if (l === "it") {
    return $decode.into(new ITALIAN());
  } else if (l === "fi") {
    return $decode.into(new FINNISH());
  } else if (l === "sv") {
    return $decode.into(new SWEDISH());
  } else if (l === "nn") {
    return $decode.into(new NYNORSK());
  } else if (l === "nb") {
    return $decode.into(new BOKMAL());
  } else if (l === "da") {
    return $decode.into(new DANISH());
  } else if (l === "et") {
    return $decode.into(new ESTONIAN());
  } else if (l === "lv") {
    return $decode.into(new LATVIAN());
  } else if (l === "lt") {
    return $decode.into(new LITHUANIAN());
  } else if (l === "is") {
    return $decode.into(new ICELANDIC());
  } else {
    return $decode.fail("TranslateTargetLanguage");
  }
}

export function language_from_iso639(l) {
  if (l === "fr") {
    return new FRENCH();
  } else if (l === "es") {
    return new SPANISH();
  } else if (l === "de") {
    return new GERMAN();
  } else if (l === "nl") {
    return new DUTCH();
  } else if (l === "pt") {
    return new PORTUGUESE();
  } else if (l === "it") {
    return new ITALIAN();
  } else if (l === "fi") {
    return new FINNISH();
  } else if (l === "sv") {
    return new SWEDISH();
  } else if (l === "nn") {
    return new NYNORSK();
  } else if (l === "nb") {
    return new BOKMAL();
  } else if (l === "da") {
    return new DANISH();
  } else if (l === "et") {
    return new ESTONIAN();
  } else if (l === "lv") {
    return new LATVIAN();
  } else if (l === "lt") {
    return new LITHUANIAN();
  } else if (l === "is") {
    return new ICELANDIC();
  } else {
    return new ENGLISH();
  }
}

export function decode_translation_target_small(target) {
  if (target instanceof Question) {
    return "pq";
  } else if (target instanceof Answer) {
    return "pa";
  } else if (target instanceof DataPoint) {
    return "dp";
  } else {
    return "no";
  }
}

export function encode_translation_target_small(target) {
  if (target === "pr") {
    return $decode.into(new Question());
  } else if (target === "pa") {
    return $decode.into(new Answer());
  } else if (target === "dp") {
    return $decode.into(new DataPoint());
  } else if (target === "no") {
    return $decode.into(new None());
  } else {
    return $decode.fail("TranslationTargetKind");
  }
}

export function translation_target_decoder(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk()) {
    let l = $[0];
    if (l === "question") {
      return new Ok(new Question());
    } else if (l === "answer") {
      return new Ok(new Answer());
    } else if (l === "data_point") {
      return new Ok(new DataPoint());
    } else if (l === "none") {
      return new Ok(new None());
    } else {
      return new Error(toList([]));
    }
  } else {
    let e = $[0];
    return new Error(e);
  }
}

export function decode_translation_target(target) {
  if (target instanceof Answer) {
    return "answer";
  } else if (target instanceof Question) {
    return "question";
  } else if (target instanceof DataPoint) {
    return "data_point";
  } else {
    return "none";
  }
}

export function encode_translation_target(target) {
  if (target === "answer") {
    return new Answer();
  } else if (target === "question") {
    return new Question();
  } else if (target === "data_point") {
    return new DataPoint();
  } else {
    return new None();
  }
}

export function decode_translation_metadata(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (target_type) => { return new TranslationMetadata(target_type); },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "target_type",
    $decode_.dynamic(translation_target_decoder),
  );
  return $decode.from(_pipe$1, dyn);
}

export function decode_translation(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (id) => {
        return $decode.parameter(
          (content) => {
            return $decode.parameter(
              (target_id) => {
                return $decode.parameter(
                  (language) => {
                    return $decode.parameter(
                      (metadata) => {
                        return new Translation(
                          id,
                          target_id,
                          content,
                          language,
                          metadata,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "content", $decode.string);
  let _pipe$3 = $decode.field(_pipe$2, "target_id", $decode.string);
  let _pipe$4 = $decode.field(
    _pipe$3,
    "language",
    $decode.then$($decode.string, decode_iso639_1),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "metadata",
    $decode_.json(decode_translation_metadata),
  );
  return $decode.from(_pipe$5, dyn);
}

function encode_translation_metadata(metadata) {
  return $json.object(
    toList([
      [
        "target_type",
        (() => {
          let _pipe = metadata.target_type;
          let _pipe$1 = decode_translation_target(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
    ]),
  );
}

export function encode_translation(translation) {
  return $json.object(
    toList([
      ["content", $json.string(translation.content)],
      ["id", $json.string(translation.id)],
      ["target_id", $json.string(translation.target_id)],
      [
        "language",
        (() => {
          let _pipe = translation.language;
          let _pipe$1 = decode_language_iso639_1(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
      [
        "metadata",
        (() => {
          let _pipe = translation.metadata;
          return encode_translation_metadata(_pipe);
        })(),
      ],
    ]),
  );
}

export function translate_decoder() {
  let _pipe = $decode.into(
    $decode.parameter(
      (content) => {
        return $decode.parameter(
          (id) => {
            return $decode.parameter(
              (kind) => {
                return $decode.parameter(
                  (org_id) => {
                    return $decode.parameter(
                      (target_language) => {
                        return new Translate(
                          content,
                          id,
                          kind,
                          org_id,
                          target_language,
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "content", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "id", $decode.string);
  let _pipe$3 = $decode.field(
    _pipe$2,
    "kind",
    $decode.then$(
      $decode.string,
      (s) => {
        if (s === "data_point") {
          return $decode.into(new DataPoint());
        } else if (s === "question") {
          return $decode.into(new Question());
        } else {
          return $decode.fail("TranslateKind");
        }
      },
    ),
  );
  let _pipe$4 = $decode.field(_pipe$3, "org_id", $decode.string);
  return $decode.field(
    _pipe$4,
    "target_language",
    $decode.then$($decode.string, decode_iso639_1),
  );
}

export function translate_to_json(data) {
  return $json.object(
    toList([
      ["content", $json.string(data.content)],
      ["id", $json.string(data.id)],
      [
        "kind",
        $json.string(
          (() => {
            let _pipe = data.kind;
            return decode_translation_target(_pipe);
          })(),
        ),
      ],
      ["org_id", $json.string(data.org_id)],
      [
        "targetLanguage",
        (() => {
          let _pipe = data.target_language;
          let _pipe$1 = decode_language_iso639_1(_pipe);
          return $json.string(_pipe$1);
        })(),
      ],
    ]),
  );
}

function create_error(content) {
  let expected = (() => {
    let _pipe = toList([
      "fr",
      "en",
      "es",
      "de",
      "nl",
      "pt",
      "it",
      "fi",
      "sv",
      "nn",
      "nb",
      "da",
      "et",
      "lv",
      "lt",
      "is",
    ]);
    return $string.join(_pipe, "|");
  })();
  return new Error(
    toList([new $dynamic.DecodeError(expected, content, toList([]))]),
  );
}

export function decode_string_to_language(dyn) {
  let $ = $dynamic.string(dyn);
  if ($.isOk() && $[0] === "fr") {
    return new Ok(new FRENCH());
  } else if ($.isOk() && $[0] === "en") {
    return new Ok(new ENGLISH());
  } else if ($.isOk() && $[0] === "es") {
    return new Ok(new SPANISH());
  } else if ($.isOk() && $[0] === "de") {
    return new Ok(new GERMAN());
  } else if ($.isOk() && $[0] === "nl") {
    return new Ok(new DUTCH());
  } else if ($.isOk() && $[0] === "pt") {
    return new Ok(new PORTUGUESE());
  } else if ($.isOk() && $[0] === "it") {
    return new Ok(new ITALIAN());
  } else if ($.isOk() && $[0] === "fi") {
    return new Ok(new FINNISH());
  } else if ($.isOk() && $[0] === "sv") {
    return new Ok(new SWEDISH());
  } else if ($.isOk() && $[0] === "nn") {
    return new Ok(new NYNORSK());
  } else if ($.isOk() && $[0] === "nb") {
    return new Ok(new BOKMAL());
  } else if ($.isOk() && $[0] === "da") {
    return new Ok(new DANISH());
  } else if ($.isOk() && $[0] === "et") {
    return new Ok(new ESTONIAN());
  } else if ($.isOk() && $[0] === "lv") {
    return new Ok(new LATVIAN());
  } else if ($.isOk() && $[0] === "lt") {
    return new Ok(new LITHUANIAN());
  } else if ($.isOk() && $[0] === "is") {
    return new Ok(new ICELANDIC());
  } else if ($.isOk()) {
    let content = $[0];
    return create_error(content);
  } else {
    let error = $[0];
    return new Error(error);
  }
}

export const language_list = /* @__PURE__ */ toList([
  /* @__PURE__ */ new ENGLISH(),
  /* @__PURE__ */ new FRENCH(),
  /* @__PURE__ */ new SPANISH(),
  /* @__PURE__ */ new GERMAN(),
  /* @__PURE__ */ new DUTCH(),
  /* @__PURE__ */ new PORTUGUESE(),
  /* @__PURE__ */ new ITALIAN(),
  /* @__PURE__ */ new FINNISH(),
  /* @__PURE__ */ new SWEDISH(),
  /* @__PURE__ */ new NYNORSK(),
  /* @__PURE__ */ new BOKMAL(),
  /* @__PURE__ */ new DANISH(),
  /* @__PURE__ */ new ESTONIAN(),
  /* @__PURE__ */ new LATVIAN(),
  /* @__PURE__ */ new LITHUANIAN(),
  /* @__PURE__ */ new ICELANDIC(),
]);
