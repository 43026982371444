/// <reference types="./transition_group.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $eff from "../../../lustre/lustre/effect.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $window from "../../../plinth/plinth/browser/window.mjs";
import * as $global from "../../../plinth/plinth/javascript/global.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $magic from "../../../sketch_magic/sketch/magic.mjs";
import * as $h from "../../../sketch_magic/sketch/magic/element/html.mjs";
import * as $unsafe from "../../ds/internals/unsafe.mjs";
import { Ok, Error, toList, prepend as listPrepend, CustomType as $CustomType } from "../../gleam.mjs";

class Started extends $CustomType {}

class InProgress extends $CustomType {}

class Ended extends $CustomType {}

class Model extends $CustomType {
  constructor(children, duration, enter_state, enter, entered, entering, exit_state, exit, exited, exiting, timer_id, timestamp) {
    super();
    this.children = children;
    this.duration = duration;
    this.enter_state = enter_state;
    this.enter = enter;
    this.entered = entered;
    this.entering = entering;
    this.exit_state = exit_state;
    this.exit = exit;
    this.exited = exited;
    this.exiting = exiting;
    this.timer_id = timer_id;
    this.timestamp = timestamp;
  }
}

class ElementFinishedMounting extends $CustomType {}

class ElementStartedEnter extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ElementFinishedEnter extends $CustomType {}

class ElementReceivedMsg extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ElementUpdatedDestroyed extends $CustomType {}

class ElementStartedExit extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ElementFinishedExit extends $CustomType {}

class UserUpdatedChildren extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedDestroy extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedDuration extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedEnter extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedEntered extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedEntering extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedExit extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedExited extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserUpdatedExiting extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function destroy(destroy) {
  return $attribute.attribute(
    "destroy",
    (() => {
      if (destroy) {
        return "true";
      } else {
        return "false";
      }
    })(),
  );
}

export function duration(duration) {
  return $attribute.property("duration", duration);
}

export function enter(class$) {
  return $attribute.property("enter", class$);
}

export function entered(class$) {
  return $attribute.property("entered", class$);
}

export function entering(class$) {
  return $attribute.property("entering", class$);
}

export function exit(class$) {
  return $attribute.property("exit", class$);
}

export function exited(class$) {
  return $attribute.property("exited", class$);
}

export function exiting(class$) {
  return $attribute.property("exiting", class$);
}

function map_attribute(decoder, msg) {
  return (dyn) => {
    let _pipe = decoder(dyn);
    return $result.map(_pipe, msg);
  };
}

function init(_) {
  let duration$1 = 1000;
  let _pipe = new Model(
    toList([]),
    duration$1,
    new Started(),
    $sketch.class$(toList([])),
    $sketch.class$(toList([])),
    $sketch.class$(toList([])),
    new None(),
    $sketch.class$(toList([])),
    $sketch.class$(toList([])),
    $sketch.class$(toList([])),
    new None(),
    $unsafe.timestamp(),
  );
  return $pair.new$(
    _pipe,
    $eff.from(
      (dispatch) => {
        $window.request_animation_frame(
          (_) => { return dispatch(new ElementFinishedMounting()); },
        );
        return undefined;
      },
    ),
  );
}

function start_enter_progress(model) {
  return [
    model.withFields({
      timestamp: $unsafe.timestamp(),
      enter_state: new InProgress()
    }),
    $eff.from(
      (dispatch) => {
        let _pipe = $global.set_timeout(
          model.duration,
          () => { return dispatch(new ElementFinishedEnter()); },
        );
        let _pipe$1 = new ElementStartedEnter(_pipe);
        return dispatch(_pipe$1);
      },
    ),
  ];
}

function store_timeout(model, timer_id) {
  let _pipe = model.withFields({ timer_id: new Some(timer_id) });
  return $pair.new$(_pipe, $eff.none());
}

function done_enter(model) {
  let _pipe = model.withFields({ enter_state: new Ended(), timer_id: new None() });
  return $pair.new$(_pipe, $eff.none());
}

function update_duration(model, duration) {
  let elapsed_time = $unsafe.timestamp() - model.timestamp;
  let duration$1 = duration - elapsed_time;
  return [
    model.withFields({ duration: duration$1 }),
    (() => {
      let $ = model.timer_id;
      if ($ instanceof $option.None) {
        return $eff.none();
      } else {
        let timer_id = $[0];
        return $eff.from(
          (dispatch) => {
            $global.clear_timeout(timer_id);
            let _pipe = $global.set_timeout(
              duration$1,
              () => { return dispatch(new ElementFinishedEnter()); },
            );
            let _pipe$1 = new ElementStartedEnter(_pipe);
            return dispatch(_pipe$1);
          },
        );
      }
    })(),
  ];
}

function start_destroying(model, destroying) {
  return $bool.guard(
    !destroying,
    [model, $eff.none()],
    () => {
      return [
        model.withFields({ exit_state: new Some(new Started()) }),
        $eff.from(
          (dispatch) => {
            $window.request_animation_frame(
              (_) => { return dispatch(new ElementUpdatedDestroyed()); },
            );
            return undefined;
          },
        ),
      ];
    },
  );
}

function start_exit_progress(model) {
  return [
    model.withFields({
      timestamp: $unsafe.timestamp(),
      exit_state: new Some(new InProgress())
    }),
    $eff.from(
      (dispatch) => {
        let _pipe = $global.set_timeout(
          model.duration,
          () => { return dispatch(new ElementFinishedExit()); },
        );
        let _pipe$1 = new ElementStartedExit(_pipe);
        dispatch(_pipe$1)
        return undefined;
      },
    ),
  ];
}

function done_exit(model) {
  return [model.withFields({ exit_state: new Some(new Ended()) }), $eff.none()];
}

function update(model, msg) {
  if (msg instanceof ElementFinishedMounting) {
    return start_enter_progress(model);
  } else if (msg instanceof ElementStartedEnter) {
    let timer_id = msg[0];
    return store_timeout(model, timer_id);
  } else if (msg instanceof ElementFinishedEnter) {
    return done_enter(model);
  } else if (msg instanceof ElementReceivedMsg) {
    let msg$1 = msg[0];
    return [model, $event.emit("msg", $unsafe.coerce(msg$1))];
  } else if (msg instanceof ElementUpdatedDestroyed) {
    return start_exit_progress(model);
  } else if (msg instanceof ElementStartedExit) {
    let timer_id = msg[0];
    return store_timeout(model, timer_id);
  } else if (msg instanceof ElementFinishedExit) {
    return done_exit(model);
  } else if (msg instanceof UserUpdatedChildren) {
    let children = msg[0];
    return [model.withFields({ children: children }), $eff.none()];
  } else if (msg instanceof UserUpdatedDestroy) {
    let destroying = msg[0];
    return start_destroying(model, destroying);
  } else if (msg instanceof UserUpdatedDuration) {
    let duration$1 = msg[0];
    return update_duration(model, duration$1);
  } else if (msg instanceof UserUpdatedEnter) {
    let enter$1 = msg[0];
    return [model.withFields({ enter: enter$1 }), $eff.none()];
  } else if (msg instanceof UserUpdatedEntered) {
    let entered$1 = msg[0];
    return [model.withFields({ entered: entered$1 }), $eff.none()];
  } else if (msg instanceof UserUpdatedEntering) {
    let entering$1 = msg[0];
    return [model.withFields({ entering: entering$1 }), $eff.none()];
  } else if (msg instanceof UserUpdatedExit) {
    let exit$1 = msg[0];
    return [model.withFields({ exit: exit$1 }), $eff.none()];
  } else if (msg instanceof UserUpdatedExited) {
    let exited$1 = msg[0];
    return [model.withFields({ exited: exited$1 }), $eff.none()];
  } else {
    let exiting$1 = msg[0];
    return [model.withFields({ exiting: exiting$1 }), $eff.none()];
  }
}

function join(class1, class2) {
  return $sketch.class$(
    toList([$sketch.compose(class1), $sketch.compose(class2)]),
  );
}

function select_enter_classes(model) {
  let $ = model.enter_state;
  if ($ instanceof Started) {
    return model.entering;
  } else if ($ instanceof InProgress) {
    return join(model.entering, model.enter);
  } else {
    return model.entered;
  }
}

function select_exit_classes(model) {
  return $option.map(
    model.exit_state,
    (exit_state) => {
      if (exit_state instanceof Started) {
        return model.exiting;
      } else if (exit_state instanceof InProgress) {
        return join(model.exiting, model.exit);
      } else {
        return model.exited;
      }
    },
  );
}

function select_classes(model) {
  let enter$1 = select_enter_classes(model);
  let exit$1 = select_exit_classes(model);
  if (exit$1 instanceof Some) {
    let exit$2 = exit$1[0];
    return $sketch.class$(
      toList([$sketch.compose(enter$1), $sketch.compose(exit$2)]),
    );
  } else {
    return enter$1;
  }
}

function view(model) {
  return $magic.render(
    toList([$magic.node()]),
    () => {
      let class$ = select_classes(model);
      let class$1 = $magic.class_name(class$);
      let _pipe = $h.div_(toList([$attribute.class$(class$1)]), model.children);
      return $element.map(
        _pipe,
        (var0) => { return new ElementReceivedMsg(var0); },
      );
    },
  );
}

const tag_name = "transition-group";

export function transition_group(attributes, children) {
  let children$1 = $attribute.property("children", children);
  let on_msg = $event.on(
    "msg",
    (e) => { return new Ok($unsafe.coerce_event_details(e)); },
  );
  return $element.element(
    tag_name,
    listPrepend(children$1, listPrepend(on_msg, attributes)),
    toList([]),
  );
}

export function register() {
  let decode = (content) => { return new Ok($unsafe.coerce(content)); };
  let _pipe = $dict.from_list(
    toList([
      [
        "children",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedChildren(var0); },
          );
        })(),
      ],
      [
        "destroy",
        (dyn) => {
          let $ = $dynamic.string(dyn);
          if ($.isOk() && $[0] === "true") {
            return new Ok(new UserUpdatedDestroy(true));
          } else if ($.isOk() && $[0] === "True") {
            return new Ok(new UserUpdatedDestroy(true));
          } else if ($.isOk() && $[0] === "false") {
            return new Ok(new UserUpdatedDestroy(false));
          } else if ($.isOk() && $[0] === "False") {
            return new Ok(new UserUpdatedDestroy(false));
          } else {
            return new Error(toList([]));
          }
        },
      ],
      [
        "duration",
        (() => {
          let _pipe = $dynamic.int;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedDuration(var0); },
          );
        })(),
      ],
      [
        "enter",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedEnter(var0); },
          );
        })(),
      ],
      [
        "entered",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedEntered(var0); },
          );
        })(),
      ],
      [
        "entering",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedEntering(var0); },
          );
        })(),
      ],
      [
        "exit",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedExit(var0); },
          );
        })(),
      ],
      [
        "exited",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedExited(var0); },
          );
        })(),
      ],
      [
        "exiting",
        (() => {
          let _pipe = decode;
          return map_attribute(
            _pipe,
            (var0) => { return new UserUpdatedExiting(var0); },
          );
        })(),
      ],
    ]),
  );
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, view, _capture);
  })(_pipe);
  return $lustre.register(_pipe$1, tag_name);
}
