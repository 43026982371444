/// <reference types="./layout.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $model from "../data/model.mjs";
import * as $route from "../data/route.mjs";
import { toList } from "../gleam.mjs";
import * as $ai_config from "../view/ai_config.mjs";
import * as $ai_search from "../view/ai_search.mjs";
import * as $content_library from "../view/content_library.mjs";
import * as $navbar from "../view/navbar.mjs";
import * as $overview from "../view/overview.mjs";
import * as $s from "../view/styles.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      $s.layout(
        toList([]),
        toList([
          $navbar.navbar(model),
          $s.main(
            toList([$a.id("layout-main")]),
            toList([
              (() => {
                let $1 = data.route;
                if ($1 instanceof $route.Overview) {
                  return $overview.view(model);
                } else if ($1 instanceof $route.ContentLibrary) {
                  return $content_library.view(model);
                } else if ($1 instanceof $route.AiConfig) {
                  return $ai_config.view(model);
                } else if ($1 instanceof $route.AiSearch) {
                  return $ai_search.view(model);
                } else {
                  return $el.none();
                }
              })(),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
