/// <reference types="./ai_config.d.mts" />
import * as $decode from "../../decode/decode.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $dynamic from "../../gleam_stdlib/gleam/dynamic.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $decode_ from "../decode/extra.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../gleam.mjs";

export class Hybrid extends $CustomType {}

export class Rerank extends $CustomType {}

export class Fusion extends $CustomType {}

export class AzureGpt4o extends $CustomType {}

export class AiConfig extends $CustomType {
  constructor(org_id, config) {
    super();
    this.org_id = org_id;
    this.config = config;
  }
}

export class Config extends $CustomType {
  constructor(style_guidelines, ai_system, llm_config, models_config, query_config, retriever_config, pii_vocabulary) {
    super();
    this.style_guidelines = style_guidelines;
    this.ai_system = ai_system;
    this.llm_config = llm_config;
    this.models_config = models_config;
    this.query_config = query_config;
    this.retriever_config = retriever_config;
    this.pii_vocabulary = pii_vocabulary;
  }
}

export class AdvancedRag extends $CustomType {}

export class LlmEnhancedVectorSearch extends $CustomType {}

export class LLMConfig extends $CustomType {
  constructor(limit) {
    super();
    this.limit = limit;
  }
}

export class ModelsConfig extends $CustomType {
  constructor(rag, rag_temperature, rag_version, style_2, summary, classifier, default_model, default_fallback_models) {
    super();
    this.rag = rag;
    this.rag_temperature = rag_temperature;
    this.rag_version = rag_version;
    this.style_2 = style_2;
    this.summary = summary;
    this.classifier = classifier;
    this.default_model = default_model;
    this.default_fallback_models = default_fallback_models;
  }
}

export class QueryConfig extends $CustomType {
  constructor(limit, alpha, parent_lookup, hyde, extract_question, translate_on) {
    super();
    this.limit = limit;
    this.alpha = alpha;
    this.parent_lookup = parent_lookup;
    this.hyde = hyde;
    this.extract_question = extract_question;
    this.translate_on = translate_on;
  }
}

export class RetrieverConfig extends $CustomType {
  constructor(option) {
    super();
    this.option = option;
  }
}

export function decode_ai_system(ai_system) {
  let expected = "advanced_rag | llm_enhanced_vector_search";
  if (ai_system === "advanced_rag") {
    return new Ok(new AdvancedRag());
  } else if (ai_system === "llm_enhanced_vector_search") {
    return new Ok(new LlmEnhancedVectorSearch());
  } else {
    let found = ai_system;
    return new Error(
      toList([new $dynamic.DecodeError(expected, found, toList([]))]),
    );
  }
}

function decode_model(option) {
  let expected = "azure/azure-gpt-4o";
  if (option === "azure/azure-gpt-4o") {
    return new Ok(new AzureGpt4o());
  } else {
    let found = option;
    return new Error(
      toList([new $dynamic.DecodeError(expected, found, toList([]))]),
    );
  }
}

function decode_retriever_option_type(option) {
  let expected = "hybrid | rerank | fusion";
  if (option === "hybrid") {
    return new Ok(new Hybrid());
  } else if (option === "rerank") {
    return new Ok(new Rerank());
  } else if (option === "fusion") {
    return new Ok(new Fusion());
  } else {
    let found = option;
    return new Error(
      toList([new $dynamic.DecodeError(expected, found, toList([]))]),
    );
  }
}

function encode_ai_system(ai_system) {
  if (ai_system instanceof AdvancedRag) {
    return $json.string("advanced_rag");
  } else {
    return $json.string("llm_enhanced_vector_search");
  }
}

function encode_llm_config(llm_config) {
  return $json.object(toList([["limit", $json.int(llm_config.limit)]]));
}

function encode_query_config(query_config) {
  return $json.object(
    toList([
      ["limit", $json.int(query_config.limit)],
      ["alpha", $json.float(query_config.alpha)],
      ["parent_lookup", $json.nullable(query_config.parent_lookup, $json.bool)],
      ["hyde", $json.nullable(query_config.hyde, $json.bool)],
      [
        "extract_question",
        $json.nullable(query_config.extract_question, $json.bool),
      ],
      ["translate_on", $json.nullable(query_config.translate_on, $json.bool)],
    ]),
  );
}

function encode_retriever_option_type(option) {
  if (option instanceof Hybrid) {
    return $json.string("hybrid");
  } else if (option instanceof Rerank) {
    return $json.string("rerank");
  } else {
    return $json.string("fusion");
  }
}

function encode_retriever_config(retriever_config) {
  return $json.object(
    toList([["option", encode_retriever_option_type(retriever_config.option)]]),
  );
}

export function encode_model(model) {
  {
    return $json.string("azure/azure-gpt-4o");
  }
}

function encode_models_config(models_config) {
  return $json.object(
    toList([
      ["rag", $json.nullable(models_config.rag, $json.string)],
      [
        "rag_temperature",
        $json.nullable(models_config.rag_temperature, $json.float),
      ],
      ["rag_version", $json.nullable(models_config.rag_version, $json.int)],
      ["style_2", $json.nullable(models_config.style_2, encode_model)],
      ["summary", $json.nullable(models_config.summary, encode_model)],
      ["classifier", $json.nullable(models_config.classifier, encode_model)],
      [
        "default_model",
        $json.nullable(models_config.default_model, encode_model),
      ],
      [
        "default_fallback_models",
        $json.array(models_config.default_fallback_models, encode_model),
      ],
    ]),
  );
}

export function encode_config(config) {
  return $json.object(
    toList([
      [
        "style_guidelines",
        $json.nullable(config.style_guidelines, $json.string),
      ],
      ["ai_system", $json.nullable(config.ai_system, encode_ai_system)],
      ["llm_config", $json.nullable(config.llm_config, encode_llm_config)],
      [
        "models_config",
        $json.nullable(config.models_config, encode_models_config),
      ],
      ["query_config", $json.nullable(config.query_config, encode_query_config)],
      [
        "retriever_config",
        $json.nullable(config.retriever_config, encode_retriever_config),
      ],
      ["pii_vocabulary", $json.array(config.pii_vocabulary, $json.string)],
    ]),
  );
}

export function encode(ai_config) {
  return $json.object(
    toList([
      ["org_id", $json.string(ai_config.org_id)],
      ["config", encode_config(ai_config.config)],
    ]),
  );
}

export function default_config() {
  let retriever_config = new RetrieverConfig(new Rerank());
  let query_config = new QueryConfig(
    50,
    0.5,
    new Some(false),
    new Some(false),
    new Some(false),
    new Some(true),
  );
  let models_config = new ModelsConfig(
    new Some("azure/azure-gpt-4o"),
    new Some(0.0),
    new Some(12),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    toList([new AzureGpt4o()]),
  );
  let llm_config = new LLMConfig(10);
  return new Config(
    new Some(""),
    new Some(new AdvancedRag()),
    new Some(llm_config),
    new Some(models_config),
    new Some(query_config),
    new Some(retriever_config),
    toList([]),
  );
}

export function current_or_default_config(config) {
  if (config instanceof $option.Some) {
    let config$1 = config[0];
    return config$1;
  } else {
    return default_config();
  }
}

export function decode_config(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (style_guidelines) => {
        return $decode.parameter(
          (ai_system) => {
            return $decode.parameter(
              (llm_config) => {
                return $decode.parameter(
                  (models_config) => {
                    return $decode.parameter(
                      (query_config) => {
                        return $decode.parameter(
                          (retriever_config) => {
                            return $decode.parameter(
                              (pii_vocabulary) => {
                                return new Config(
                                  style_guidelines,
                                  ai_system,
                                  llm_config,
                                  models_config,
                                  query_config,
                                  retriever_config,
                                  pii_vocabulary,
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "style_guidelines",
    $decode.optional($decode.string),
  );
  let _pipe$2 = $decode.field(
    _pipe$1,
    "ai_system",
    $decode.optional($decode_.from_string(decode_ai_system)),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "llm_config",
    $decode.optional($decode_.dynamic(decode_llm_config)),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "models_config",
    $decode.optional($decode_.dynamic(decode_models_config)),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "query_config",
    $decode.optional($decode_.dynamic(decode_query_config)),
  );
  let _pipe$6 = $decode.field(
    _pipe$5,
    "retriever_config",
    $decode.optional($decode_.dynamic(decode_retriever_config)),
  );
  let _pipe$7 = $decode.field(
    _pipe$6,
    "pii_vocabulary",
    $decode.list($decode.string),
  );
  return $decode.from(_pipe$7, dyn);
}

export function decode(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (org_id) => {
        return $decode.parameter(
          (config) => { return new AiConfig(org_id, config); },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "org_id", $decode.string);
  let _pipe$2 = $decode.field(_pipe$1, "config", $decode_.json(decode_config));
  return $decode.from(_pipe$2, dyn);
}

function decode_llm_config(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((limit) => { return new LLMConfig(limit); }),
  );
  let _pipe$1 = $decode.field(_pipe, "limit", $decode.int);
  return $decode.from(_pipe$1, dyn);
}

function decode_models_config(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (rag) => {
        return $decode.parameter(
          (rag_temperature) => {
            return $decode.parameter(
              (rag_version) => {
                return $decode.parameter(
                  (style_2) => {
                    return $decode.parameter(
                      (summary) => {
                        return $decode.parameter(
                          (classifier) => {
                            return $decode.parameter(
                              (default_model) => {
                                return $decode.parameter(
                                  (default_fallback_models) => {
                                    return new ModelsConfig(
                                      rag,
                                      rag_temperature,
                                      rag_version,
                                      style_2,
                                      summary,
                                      classifier,
                                      default_model,
                                      default_fallback_models,
                                    );
                                  },
                                );
                              },
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "rag", $decode.optional($decode.string));
  let _pipe$2 = $decode.field(
    _pipe$1,
    "rag_temperature",
    $decode.optional(
      $decode.one_of(
        toList([$decode.float, $decode.map($decode.int, $int.to_float)]),
      ),
    ),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "rag_version",
    $decode.optional($decode.int),
  );
  let _pipe$4 = $decode.field(
    _pipe$3,
    "style_2",
    $decode.optional($decode_.from_string(decode_model)),
  );
  let _pipe$5 = $decode.field(
    _pipe$4,
    "summary",
    $decode.optional($decode_.from_string(decode_model)),
  );
  let _pipe$6 = $decode.field(
    _pipe$5,
    "classifier",
    $decode.optional($decode_.from_string(decode_model)),
  );
  let _pipe$7 = $decode.field(
    _pipe$6,
    "default_model",
    $decode.optional($decode_.from_string(decode_model)),
  );
  let _pipe$8 = $decode.field(
    _pipe$7,
    "default_fallback_models",
    $decode.list($decode_.from_string(decode_model)),
  );
  return $decode.from(_pipe$8, dyn);
}

function decode_query_config(dyn) {
  let _pipe = $decode.into(
    $decode.parameter(
      (limit) => {
        return $decode.parameter(
          (alpha) => {
            return $decode.parameter(
              (parent_lookup) => {
                return $decode.parameter(
                  (hyde) => {
                    return $decode.parameter(
                      (extract_question) => {
                        return $decode.parameter(
                          (translate_on) => {
                            return new QueryConfig(
                              limit,
                              alpha,
                              parent_lookup,
                              hyde,
                              extract_question,
                              translate_on,
                            );
                          },
                        );
                      },
                    );
                  },
                );
              },
            );
          },
        );
      },
    ),
  );
  let _pipe$1 = $decode.field(_pipe, "limit", $decode.int);
  let _pipe$2 = $decode.field(
    _pipe$1,
    "alpha",
    $decode.one_of(
      toList([$decode.float, $decode.map($decode.int, $int.to_float)]),
    ),
  );
  let _pipe$3 = $decode.field(
    _pipe$2,
    "parent_lookup",
    $decode.optional($decode.bool),
  );
  let _pipe$4 = $decode.field(_pipe$3, "hyde", $decode.optional($decode.bool));
  let _pipe$5 = $decode.field(
    _pipe$4,
    "extract_question",
    $decode.optional($decode.bool),
  );
  let _pipe$6 = $decode.field(
    _pipe$5,
    "translate_on",
    $decode.optional($decode.bool),
  );
  return $decode.from(_pipe$6, dyn);
}

function decode_retriever_config(dyn) {
  let _pipe = $decode.into(
    $decode.parameter((option) => { return new RetrieverConfig(option); }),
  );
  let _pipe$1 = $decode.field(
    _pipe,
    "option",
    $decode_.from_string(decode_retriever_option_type),
  );
  return $decode.from(_pipe$1, dyn);
}
