/// <reference types="./navbar.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $colors from "../../ds/ds/colors.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $element from "../../lustre/lustre/element.mjs";
import * as $html from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";
import * as $s from "../view/styles/navbar.mjs";

class NavLink extends $CustomType {
  constructor(icon, title, link) {
    super();
    this.icon = icon;
    this.title = title;
    this.link = link;
  }
}

function view_navlinks(model, colour, navlinks) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let route = data.route;
  return $list.map(
    navlinks,
    (item) => {
      let permitted = true;
      return $bool.guard(
        !permitted,
        $element.none(),
        () => {
          let href = (() => {
            let _pipe = item.link;
            let _pipe$1 = $list.first(_pipe);
            let _pipe$2 = $result.map(_pipe$1, $route.to_uri);
            let _pipe$3 = $result.map(_pipe$2, $uri.to_string);
            return $result.unwrap(_pipe$3, "#");
          })();
          let active = $list.contains(item.link, route);
          return $s.nav_link(
            colour,
            active,
            toList([$a.href(href)]),
            toList([$s.icon_wrapper(item.icon()), $html.text(item.title)]),
          );
        },
      );
    },
  );
}

const navlinks = /* @__PURE__ */ toList([
  /* @__PURE__ */ new NavLink(
    $icons.map,
    "Overview",
    /* @__PURE__ */ toList([/* @__PURE__ */ new $route.Overview()]),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.book,
    "Content Library",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.ContentLibrary(
        /* @__PURE__ */ new $route.DataSources(),
      ),
    ]),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.wrench,
    "AI Config",
    /* @__PURE__ */ toList([
      /* @__PURE__ */ new $route.AiConfig(
        /* @__PURE__ */ new $route.StyleGuidelines(),
      ),
    ]),
  ),
  /* @__PURE__ */ new NavLink(
    $icons.lightbulb_flash,
    "AI Search",
    /* @__PURE__ */ toList([/* @__PURE__ */ new $route.AiSearch()]),
  ),
]);

export function navbar(model) {
  return $s.navbar(
    toList([]),
    toList([
      $s.navbar_main(
        toList([]),
        toList([
          $s.nav_links(
            toList([]),
            view_navlinks(model, $colors.light.onyx, navlinks),
          ),
        ]),
      ),
    ]),
  );
}
