/// <reference types="./input.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import { blurTarget as blur_target } from "../../ds.ffi.mjs";
import * as $s from "../../ds/internals/styles/input.mjs";
import * as $gleam from "../../gleam.mjs";
import { Ok, toList, CustomType as $CustomType } from "../../gleam.mjs";

class Model extends $CustomType {
  constructor(label, placeholder, type_, disabled, error, value, icon, on_input, on_submit, on_blur, multiline, id, invisible, enter_submit) {
    super();
    this.label = label;
    this.placeholder = placeholder;
    this.type_ = type_;
    this.disabled = disabled;
    this.error = error;
    this.value = value;
    this.icon = icon;
    this.on_input = on_input;
    this.on_submit = on_submit;
    this.on_blur = on_blur;
    this.multiline = multiline;
    this.id = id;
    this.invisible = invisible;
    this.enter_submit = enter_submit;
  }
}

class Label extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Placeholder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Type extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Disabled extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Error extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Value extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Icon extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnBlur extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnSubmit extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Multiline extends $CustomType {}

class Invisible extends $CustomType {}

class Id extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class EnterSubmit extends $CustomType {}

function attributes_to_model(attrs) {
  return $list.fold(
    attrs,
    new Model(
      new None(),
      new None(),
      "text",
      new None(),
      new None(),
      "",
      new None(),
      new None(),
      new None(),
      new None(),
      false,
      new None(),
      false,
      false,
    ),
    (all, attr) => {
      if (attr instanceof Label) {
        let content = attr[0];
        return all.withFields({ label: new Some(content) });
      } else if (attr instanceof Placeholder) {
        let content = attr[0];
        return all.withFields({ placeholder: new Some(content) });
      } else if (attr instanceof Type) {
        let content = attr[0];
        return all.withFields({ type_: content });
      } else if (attr instanceof Disabled) {
        let content = attr[0];
        return all.withFields({ disabled: new Some(content) });
      } else if (attr instanceof Error) {
        let content = attr[0];
        return all.withFields({ error: new Some(content) });
      } else if (attr instanceof Value) {
        let content = attr[0];
        return all.withFields({ value: content });
      } else if (attr instanceof Icon) {
        let content = attr[0];
        return all.withFields({ icon: new Some(content) });
      } else if (attr instanceof OnInput) {
        let handler = attr[0];
        return all.withFields({ on_input: new Some(handler) });
      } else if (attr instanceof OnSubmit) {
        let handler = attr[0];
        return all.withFields({ on_submit: new Some(handler) });
      } else if (attr instanceof Multiline) {
        return all.withFields({ multiline: true });
      } else if (attr instanceof Invisible) {
        return all.withFields({ invisible: true });
      } else if (attr instanceof EnterSubmit) {
        return all.withFields({ enter_submit: true });
      } else if (attr instanceof Id) {
        let id$1 = attr[0];
        return all.withFields({ id: new Some(id$1) });
      } else {
        let handler = attr[0];
        return all.withFields({ on_blur: new Some(handler) });
      }
    },
  );
}

export function label(text) {
  return new Label(text);
}

export function placeholder(text) {
  return new Placeholder(text);
}

export function type_(text) {
  return new Type(text);
}

export function disabled(value) {
  return new Disabled(value);
}

export function error(value) {
  return new Error(value);
}

export function value(value) {
  return new Value(value);
}

export function icon(value) {
  return new Icon(value);
}

export function on_input(handler) {
  return new OnInput(handler);
}

export function on_submit(handler) {
  return new OnSubmit(handler);
}

export function on_blur(handler) {
  return new OnBlur(handler);
}

export function multiline() {
  return new Multiline();
}

export function invisible() {
  return new Invisible();
}

export function enter_submit() {
  return new EnterSubmit();
}

export function id(id) {
  return new Id(id);
}

function select_input_class(model) {
  let $ = model.invisible;
  if ($) {
    return $s.invisible;
  } else {
    let $1 = model.multiline;
    if (!$1) {
      let $2 = model.disabled;
      let $3 = model.error;
      if ($2 instanceof Some && $2[0]) {
        return $s.disabled;
      } else if ($3 instanceof Some) {
        return $s.error;
      } else {
        return $s.standard;
      }
    } else {
      let $2 = model.disabled;
      let $3 = model.error;
      if ($2 instanceof Some && $2[0]) {
        return $s.disabled_multiline;
      } else if ($3 instanceof Some) {
        return $s.error_multiline;
      } else {
        return $s.standard_multiline;
      }
    }
  }
}

function view_content(model) {
  let class$ = select_input_class(model);
  return $s.input_wrapper(
    toList([]),
    toList([
      class$(
        $option.is_some(model.icon),
        toList([
          (() => {
            let _pipe = model.disabled;
            let _pipe$1 = $option.map(_pipe, $a.disabled);
            return $option.unwrap(_pipe$1, $a.none());
          })(),
          (() => {
            let _pipe = model.id;
            let _pipe$1 = $option.map(_pipe, $a.id);
            return $option.unwrap(_pipe$1, $a.none());
          })(),
          $a.type_(model.type_),
          $a.value(model.value),
          (() => {
            let $ = model.multiline;
            if ($) {
              return $a.rows(5);
            } else {
              return $a.none();
            }
          })(),
          $e.on(
            "blur",
            (_) => {
              let $ = model.on_blur;
              if ($ instanceof $option.None) {
                return new $gleam.Error(toList([]));
              } else {
                let msg = $[0];
                return new Ok(msg);
              }
            },
          ),
          $e.on(
            "keydown",
            (dyn) => {
              return $result.try$(
                $dynamic.field("metaKey", $dynamic.bool)(dyn),
                (meta) => {
                  let meta$1 = (() => {
                    let $ = model.enter_submit;
                    if ($) {
                      return true;
                    } else {
                      return meta;
                    }
                  })();
                  return $result.try$(
                    $dynamic.field("key", $dynamic.string)(dyn),
                    (key) => {
                      let $ = model.on_submit;
                      if (key === "Enter" && meta$1 && $ instanceof $option.Some) {
                        let handler = $[0];
                        blur_target(dyn);
                        return new Ok(handler);
                      } else {
                        return new $gleam.Error(toList([]));
                      }
                    },
                  );
                },
              );
            },
          ),
          (() => {
            let _pipe = model.on_input;
            let _pipe$1 = $option.map(_pipe, $e.on_input);
            return $option.unwrap(_pipe$1, $a.none());
          })(),
          (() => {
            let _pipe = model.placeholder;
            let _pipe$1 = $option.map(_pipe, $a.placeholder);
            return $option.unwrap(_pipe$1, $a.none());
          })(),
        ]),
      ),
      (() => {
        let $ = model.icon;
        if ($ instanceof None) {
          return $el.none();
        } else {
          let icon$1 = $[0];
          return $s.icon_display_wrapper(
            toList([
              (() => {
                let $1 = model.value;
                let $2 = model.on_submit;
                if ($2 instanceof $option.Some && ($1 !== "")) {
                  let i = $1;
                  let handler = $2[0];
                  return $e.on_click(handler);
                } else {
                  return $a.none();
                }
              })(),
              $a.style(
                toList([
                  [
                    "pointer-events",
                    (() => {
                      let $1 = model.value;
                      if ($1 === "") {
                        return "none";
                      } else {
                        return "default";
                      }
                    })(),
                  ],
                ]),
              ),
            ]),
            toList([$s.icon_display(toList([]), toList([icon$1]))]),
          );
        }
      })(),
    ]),
  );
}

function view_error(model) {
  let $ = model.error;
  if ($ instanceof None) {
    return $el.none();
  } else {
    let error$1 = $[0];
    return $s.error_label(toList([]), toList([$el.text(error$1)]));
  }
}

function view(model) {
  return $s.wrapper(
    toList([]),
    (() => {
      let $ = model.label;
      if ($ instanceof Some) {
        let label$1 = $[0];
        return toList([
          $el.text(label$1),
          view_content(model),
          view_error(model),
        ]);
      } else {
        return toList([view_content(model), view_error(model)]);
      }
    })(),
  );
}

export function input(attrs) {
  let _pipe = attrs;
  let _pipe$1 = attributes_to_model(_pipe);
  return view(_pipe$1);
}
